<template>
  <div class="sys-wrap">
    <!-- 主页面 -->
    <el-container style="height: 100%">
      <el-aside :width="isCollapse ? '64px' : '210px'">
        <div v-if="!isCollapse" class="logo" @click="logo">广告管理平台</div>
        <div v-else class="logo" @click="logo">
          <i class="el-icon-monitor"></i>
        </div>
        <el-menu
          router
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
          :collapse-transition="false"
        >
          <MenuTree :menus="$store.state.userAuth.menus"></MenuTree> </el-menu
      ></el-aside>
      <el-container>
        <el-header height="50px">
          <i
            class="fold"
            :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="toggleCollapse"
          ></i>
          <el-dropdown trigger="click" @command="handleCommand">
            <el-avatar icon="el-icon-user" :size="30"></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled
                >账号：{{ $store.state.userAuth.userName }}</el-dropdown-item
              >
              <el-dropdown-item divided icon="el-icon-user" command="selfInfo"
                >个人信息</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-edit" command="editSelf"
                >修改信息</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-key" command="editPassword"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-switch-button" command="logout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
    <!-- 业务弹框-个人信息 -->
    <el-dialog
      title="个人信息"
      :visible.sync="dialogSelfInfo"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-descriptions
        v-loading="selfInfoLoading"
        :column="1"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-descriptions-item label="用户ID">
          {{ selfInfoData.id }}
        </el-descriptions-item>
        <el-descriptions-item label="用户名">
          {{ selfInfoData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="真实姓名">
          {{ selfInfoData.realName }}
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          {{ this.sexFormatter(selfInfoData) }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ selfInfoData.phone }}
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">
          {{ selfInfoData.email }}
        </el-descriptions-item>
        <el-descriptions-item label="角色">
          {{ selfInfoData.roleName }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ selfInfoData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item label="更新时间">
          {{ selfInfoData.updateTime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 业务弹框-修改信息 -->
    <el-dialog
      title="修改信息"
      :visible.sync="dialogEditSelf"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="selfEditForm"
        v-loading="initEditLoading"
        :model="selfEditForm"
        :rules="selfRules"
        status-icon
        label-position="top"
        size="medium"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="真实姓名" prop="realName">
          <el-tooltip
            content="真实姓名必须为中文、字母或数字，且不超过20位"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="selfEditForm.realName"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="selfEditForm.sex">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="selfEditForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="selfEditForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="selfEditLoading" @click="selfEdit"
          >确定</el-button
        >
        <el-button @click="dialogEditSelf = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 业务弹框-修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogEditPassword"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="editPasswordForm"
        :model="editPasswordForm"
        :rules="editPasswordRules"
        status-icon
        label-position="top"
        size="medium"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="editPasswordForm.oldPassword"
            type="password"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-tooltip
            content="密码必须6~12位字符，且不能出现不可见字符"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="editPasswordForm.newPassword"
              type="password"
              placeholder="请输入新密码"
            ></el-input
          ></el-tooltip>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            v-model="editPasswordForm.rePassword"
            type="password"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="editPasswordLoading"
          @click="editPassword"
          >确定</el-button
        >
        <el-button @click="dialogEditPassword = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MenuTree from "../components/menuTree";
export default {
  name: "Main",
  components: {
    MenuTree,
  },
  data() {
    let vRePassword = (rule, value, callback) => {
      if (value !== this.editPasswordForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isCollapse: false,
      dialogSelfInfo: false,
      selfInfoLoading: false,
      selfInfoData: {},
      dialogEditSelf: false,
      sexOptions: [
        {
          name: "男",
          value: 1,
        },
        {
          name: "女",
          value: 2,
        },
      ],
      selfEditForm: {},
      selfRules: {
        realName: [
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9]{0,20}$/,
            message: "真实姓名格式不正确",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [
          {
            pattern: /^1\d{10}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
      },
      initEditLoading: false,
      selfEditLoading: false,
      dialogEditPassword: false,
      editPasswordForm: {},
      editPasswordRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[\S]{6,12}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: vRePassword, trigger: "blur" },
        ],
      },
      editPasswordLoading: false,
    };
  },
  methods: {
    sexFormatter(row) {
      for (let i = 0, len = this.sexOptions.length; i < len; i++) {
        let item = this.sexOptions[i];
        if (row.sex === item.value) {
          return item.name;
        }
      }
      return null;
    },
    logo() {
      this.$router.push({ name: "Index" });
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleCommand(command) {
      switch (command) {
        case "selfInfo":
          this.selfInfoLoading = true;
          this.$http
            .get("/system/user/getSelfInfo")
            .then((response) => {
              if (response.data.code === 200) {
                this.selfInfoData = response.data.data;
              }
            })
            .finally(() => {
              this.selfInfoLoading = false;
            });
          this.dialogSelfInfo = true;
          break;
        case "editSelf":
          this.initEditLoading = true;
          this.$http
            .get("/system/user/getSelfInfo")
            .then((response) => {
              if (response.data.code === 200) {
                let retData = response.data.data;
                this.selfEditForm = {
                  realName: retData.realName,
                  sex: retData.sex,
                  phone: retData.phone,
                  email: retData.email,
                };
              }
            })
            .finally(() => {
              this.initEditLoading = false;
            });
          this.selfEditLoading = false;
          this.dialogEditSelf = true;
          break;
        case "editPassword":
          this.editPasswordForm = {};
          this.editPasswordLoading = false;
          this.dialogEditPassword = true;
          break;
        case "logout":
          this.$http.get("/system/logout").then(() => {
            this.$message({
              message: "退出登录",
              type: "success",
              duration: 1000,
              onClose: () => {
                this.$router.push({ name: "Login" });
                this.$store.commit("delUserAuth");
              },
            });
          });
          break;
      }
    },
    selfEdit() {
      this.$refs["selfEditForm"].validate((valid) => {
        if (valid) {
          this.selfEditLoading = true;
          this.$http
            .post("/system/user/editSelf", this.selfEditForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEditSelf = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1000,
                });
              }
            })
            .finally(() => {
              this.selfEditLoading = false;
            });
        }
      });
    },
    editPassword() {
      this.$refs["editPasswordForm"].validate((valid) => {
        if (valid) {
          this.editPasswordLoading = true;
          let editSelfPassword = {
            oldPassword: this.$encodePassword(
              this.editPasswordForm.oldPassword
            ),
            newPassword: this.$encodePassword(
              this.editPasswordForm.newPassword
            ),
            rePassword: this.$encodePassword(this.editPasswordForm.rePassword),
          };
          this.$http
            .post("/system/user/editSelfPassword", editSelfPassword)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEditPassword = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1000,
                });
              }
            })
            .finally(() => {
              this.editPasswordLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.sys-wrap {
  width: 100vw;
  height: 100vh;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.el-aside {
  background-color: #545c64;
}
.logo {
  height: 50px;
  color: #fff;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fold {
  color: #545c64;
  font-size: 20px;
  cursor: pointer;
}
.el-avatar {
  cursor: pointer;
  vertical-align: middle;
}
.el-menu {
  border: 0;
}
.el-main {
  background-color: #f2f2f2;
}
</style>
